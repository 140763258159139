import translateServer from '@utils_backend_only/serverside.translation';
import { FaCopyright } from "@react-icons/all-files/fa/FaCopyright";

const Footer = async ({ lang }) => {

  return (
    <footer className="footer">
      <span>{translateServer('Copyrights', lang)}</span>
      <FaCopyright />
      <span>{translateServer('SparkTD 2024', lang)}</span>
    </footer>
  )
}

export default Footer