import { SEO_API } from "@constants"
import axios from "axios"
import { dev_console_log } from "@utils"
// import fs from 'fs';
// import path from "path"

// const cachedDictFilePath = path.resolve('./cache/dict.json');

const translateServer = async (keyword, lang) => {
    const dict = await import("@cache/dict.json")
    
    // const dict = JSON.parse(fs.readFileSync(cachedDictFilePath, 'utf-8'));
    // const dict = await axios.get(`${SEO_API}/translation/getDict/lwe`).then(res => res.data);

    let translation = keyword
    const keywordTranslations = dict[keyword] || {}

    const fetchTranslation = async () => {
        try {
            const data = await axios.post(`${SEO_API}/translation/addOrUpdateTrans`, {
                keyword, lang, solution: "lwe"
            }, {
                headers: {
                    API_KEY: process.env.NEXT_PUBLIC_SEO_API_KEY
                }
            }).then(res => res.data)

            // upate translation 
            translation = data[lang]

            // // update cache dict
            // keywordTranslations[lang] = data[lang]
            // dict[keyword] = keywordTranslations

            // // Write the updated array back to the cache file
            // fs.writeFileSync(cachedDictFilePath, JSON.stringify(dict, null, 2), 'utf-8');
        } catch (error) {
            dev_console_log(error);
        }
    }

    if (keywordTranslations[lang]) translation = keywordTranslations[lang]
    else await fetchTranslation()

    return translation
}

export default translateServer