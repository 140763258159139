"use client"

import { BACKEND_ROOT } from "@constants"
import { AxiosRequest , dev_console_log } from "@utils"
import { Fragment, useState } from "react"
import Swal from "sweetalert2"
import PostForm from "./PostForm"
import { CustomModal, withStore } from "@components"
import PostOptions from "./PostOptions"
import { useRouter } from "next/navigation"
import {ImExit} from "@react-icons/all-files/im/ImExit";
import { useSelector } from "react-redux"
import { getUser } from "@lib/reducers/userReducer"


const PostPageOptions = ({ p }) => {
    const user = useSelector(getUser)
    const [modal, setModal] = useState({ open: false })
    const router = useRouter()

    // helpers
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    // post layer
    const handleEditPost = async (content) => {
        await AxiosRequest.put(`${BACKEND_ROOT}/forum/updatePost/${p._id}`, { content })
            .then(res => {
                const postContent = document.getElementById(`post-${p._id}-content`)
                postContent.innerHTML = res.data.content

                closeModal()
            }).catch(err => {
                dev_console_log(err);
            })
    }


    const handleDeletePost = () => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm delete!",
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Delete",
            showCloseButton: true,
            showCancelButton: true,
            showLoaderOnDeny: true
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.delete(`${BACKEND_ROOT}/forum/deletePost/${post._id}`)
                    .then(res => {
                        if (res.status == 200) router.push(`/${lang}/course/${id}/forum`)
                    }).catch(err => {
                        dev_console_log(err);
                    })
            }
        }).catch(err => {
            dev_console_log(err)
        })
    }


    const handleReportPost = () => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm report!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Report",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.post(`${BACKEND_ROOT}/forum/report/Post/${post._id}`)
                    .then(res => {
                        if (res.status == 201) router.push(`/${lang}/course/${id}/forum`)
                    }).catch(err => {
                        dev_console_log(err);
                    })
            }
        }).catch(err => {
            dev_console_log(err)
        })
    }

    return (
        <Fragment>
            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-4xl scale-90`}>
                <PostForm initial={p} handleFormAction={handleEditPost} />
            </CustomModal>

            <div className='flex items-center justify-end'>
                {/* post options */}
                {
                    user &&
                    <PostOptions
                        p={p}
                        handleEditPost={() => openModal()}
                        handleDeletePost={handleDeletePost}
                        handleReportPost={handleReportPost}
                    />
                }

                {/* navigate back button */}
                <button onClick={() => router.back()} className='text-gray-500 p-2 rounded-full hover:bg-gray-200 transition-colors duration-300 text-[18px]'>
                    <ImExit />
                </button>
            </div>
        </Fragment>
    )
}

export default withStore(PostPageOptions)