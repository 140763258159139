"use client"

import { CustomModal, withStore } from "@components"
import PostForm from "./PostForm"
import { Fragment, useState } from "react"
import { BACKEND_ROOT } from "@constants"
import {FaPlus} from "@react-icons/all-files/fa/FaPlus"
import { AxiosRequest , dev_console_log } from "@utils"
import PostCard from "./PostCard"
import { createRoot } from "react-dom/client";
import { useRouter } from "next/navigation"


const NewPostButton = ({ user, courseId, children, lang }) => {
    const [modal, setModal] = useState({ open: false })
    const router = useRouter()

    // helpers
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    const handleNewPost = async (content) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/forum/addPost/${courseId}`, { content })
            .then(res => {
                const newPost = res.data
                const forumContainer = document.getElementById(`forum-${courseId}`);

                // Create a new div for the PostCard component
                const container = document.createElement("div");

                // Insert the new div after the first child
                forumContainer.insertBefore(container, forumContainer.children[1]);

                // Create a React root and render the PostCard directly into the new div
                const root = createRoot(container);
                root.render(
                    <PostCard
                        p={newPost}
                        user={user}
                        lang={lang}
                    />
                )

                // container.replaceWith(container.firstChild)

                closeModal()
            }).catch(err => {
                dev_console_log(err);
            })
    }

    const PostFormWithStore = withStore(PostForm)
    return (
        <Fragment>
            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-4xl scale-90`}>
                <PostFormWithStore handleFormAction={handleNewPost} />
            </CustomModal>

            <button onClick={() => {
                if (!user) return router.push(`/${lang}/login`)

                openModal()
            }} className="w-full max-w-3xl mx-auto flex gap-x-2 justify-center items-center text-gray-500 bg-gray-100 hover:bg-gray-200 border-dashed border-2 border-gray-300 rounded px-4 py-2 transition-colors duration-300 shadow">
                <FaPlus />
                <span>{children}</span>
            </button>
        </Fragment>
    )
}

export default NewPostButton