"use client"

import { useEffect, Fragment } from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { AxiosRequest , dev_console_log } from "@utils"
import { socketEmit } from "@lib/reducers/socketReducer"
import { usePathname } from "next/navigation"
import { BACKEND_ROOT } from "@constants"
import { useTranslate } from '@hooks';
import Script from 'next/script';


const ActivityTracker = () => {
    const pathname = usePathname()
    const dispatch = useDispatch()
    const translate = useTranslate()
    
    
    useEffect(() => {
        let ytPlayers = [];
        let dayActivityId;
        let timeoutId;
        let intervalId;

        let active = true;
        const inactivityTimeout = 5 * 60 * 1000;
        const events = ['keydown', 'scroll', 'click', 'mousemove'];
        const updateDurationTime = 5 * 60 * 1000; // 5 minutes in milliseconds


        const resetTimer = () => {
            clearTimeout(timeoutId);

            if (!active) {
                active = true;
                Swal.close();
                
                dispatch(socketEmit({
                    event: 'active',
                    data: { dayActivityId }
                }));
            }

            timeoutId = setTimeout(() => {
                if (ytPlayers.some(ytP => ytP.getPlayerState() == YT.PlayerState.PLAYING)) {
                    dev_console_log(`some video is playing`);

                    return resetTimer()
                }

                active = false;

                Swal.fire({
                    icon: "question",
                    showConfirmButton: false,
                    title: translate("where did you go ?"),
                });

                dispatch(socketEmit({
                    event: 'inactive',
                    data: { dayActivityId }
                }));
            }, inactivityTimeout);
        };


        const handleVisibilitychange = () => {
            if (document.hidden) {
                clearTimeout(timeoutId);
                active = false;

                Swal.fire({
                    icon: "question",
                    showConfirmButton: false,
                    title: translate("where did you go ?"),
                });

                dispatch(socketEmit({
                    event: 'left-window',
                    data: { dayActivityId }
                }));
            }
        };


        const updateDuration = () => {
            AxiosRequest.post(`${BACKEND_ROOT}/user/updateDayActivityDuration`, { dayActivityId })
                .then(res => {
                    dev_console_log(`day activity duration updated: ${res.data.durationInMinutes} minutes`);
                })
                .catch(error => {
                    console.error(error);
                });
        };


        // init youtube videos players when youtube api ready
        window.onYouTubeIframeAPIReady = () => {
            const ytIframes = document.querySelectorAll('iframe[name="ka-player-iframe"]');
            if (ytIframes.length > 0) {
                ytIframes.forEach(iframe => {
                    // adjust width of iframe
                    iframe.style.marginLeft = 'auto';
                    iframe.style.marginRight = 'auto';
                    iframe.style.maxWidth = `${iframe.offsetWidth}px`;
                    iframe.width = "100%"

                    ytPlayers.push(new YT.Player(iframe.id))
                })

                dev_console_log(`found ${ytIframes.length} yt-iframes, ${ytPlayers.length} yt-players are loaded`);
            } else {
                dev_console_log(`couldn't find any yt-iframes`);
            }
        }

        
        AxiosRequest.post(`${BACKEND_ROOT}/user/getOrCreateDayActivity`, { route: pathname })
            .then(res => {
                dayActivityId = res.data._id;
    
                // Set up event listeners for user activity
                events.forEach(event => window.addEventListener(event, resetTimer));
                window.addEventListener('visibilitychange', handleVisibilitychange);

                // Initialize the timer
                resetTimer();

                // Start the interval to trigger update duration every 5 minutes
                intervalId = setInterval(updateDuration, updateDurationTime);
            })
            .catch(err => {
                console.error(err);
            });


        // Cleanup function to remove listeners and timeouts
        return () => {
            // dev_console_log('cleared');
            
            clearTimeout(timeoutId);
            clearInterval(intervalId);

            events.forEach(event => window.removeEventListener(event, resetTimer));
            window.removeEventListener('visibilitychange', handleVisibilitychange);
        };
    }, []);
    

    return (
        <Fragment>
            <div hidden id="activity-tracker" />
            <Script
                src="https://www.youtube.com/iframe_api"
                strategy="afterInteractive"
            />
        </Fragment>
    )
};

export default ActivityTracker;
