"use client"

import { BACKEND_ROOT } from "@constants"
import { Switch } from "@headlessui/react"
import { useTranslate } from "@hooks"
import { AxiosRequest , dev_console_log } from "@utils"
import { Fragment, useEffect, useMemo, useState } from "react"
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import {FaPlus} from "@react-icons/all-files/fa/FaPlus"
import {FaStar} from "@react-icons/all-files/fa/FaStar"
import {FaGraduationCap} from "@react-icons/all-files/fa/FaGraduationCap"
import {MdDelete} from "@react-icons/all-files/md/MdDelete"
import {MdErrorOutline} from "@react-icons/all-files/md/MdErrorOutline"
import Select from "react-select"

  
const CourseForm = ({ initial, handleFormAction, givenOptions }) => {
    const translate = useTranslate()

    const [formData, setFormData] = useState(initial || { type: 'academic', learningOutcomes: [] })
    const [errors, setErrors] = useState({
        ...(formData.learningOutcomes.length == 0 && {
            learningOutcomes: "You have to add 1 learning outcome at least!"
        })
    })
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState(givenOptions)

    const required = useMemo(() => {
        const mainFields = {
            "title": translate("Course Title" ),
            "learningOutcomes": translate("Learning Outcomes" ),
            ...(!initial && { "file": translate("Cover Image" ) }),
        }

        const academicFields = {
            "educationalSystem": translate("Educational System" ),
            "gradeLevel": translate("Grade Level" ),
        }

        const professionalFields = {
            "pathway": translate("Course Pathway" ),
            "order": translate("Order in Pathway" ),
        }

        return formData.type == 'academic' ? { ...mainFields, ...academicFields } : { ...mainFields, ...professionalFields }
    }, [formData.type])

    useEffect(()=>{
        const getOptions = async () => {
            try {
                const educationalSystems = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/EducationalSystem`)
                const gradeLevels = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/GradeLevel`)
                const pathways = await AxiosRequest.get(`${BACKEND_ROOT}/constants/allRecords/Pathway`)

                setOptions({
                    educationalSystems: educationalSystems.data.map(option => ({ value: option._id, label: translate(option.name ) })),
                    gradeLevels: gradeLevels.data.map(option => ({ value: option._id, label: translate(option.name ) })),
                    pathways: pathways.data.map(option => ({ value: option._id, label: translate(option.name ) })),
                })
            } catch (error) {
                dev_console_log(error);
            }
        }

        !givenOptions && getOptions()
    }, [])

    const validationRules = {
        title: { invalid: (value) => value.length < 3, error: "Must be 3 letters at least!" },
        file: { invalid: (value) => !value?.type?.startsWith('image/'), error: "Must be image file (jpg, png, etc..)!" },
        order: { invalid: (value) => !/^[0-9]+$/.test(value) || value <= 0, error: "Must be a number greater than 0!" },
    }


    const validateField = (fieldName, fieldValue) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[fieldName]

        if (validationRules[fieldName].invalid(fieldValue)) {
            updatedErrors[fieldName] = validationRules[fieldName].error
        }

        setErrors(updatedErrors)
    }


    const handleChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value

        setFormData({
            ...formData,
            [fieldName]: fieldValue,
        })

        validateField(fieldName, fieldValue)
    }


    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true);
        await handleFormAction(formData, setErrors, required);
        setLoading(false);
    }

    
    return (
        <div className="flex flex-col justify-center items-center gap-y-2 ">
            <h1 className="text-2xl font-semibold text-blue-500">{translate(initial ? `Update` : "Add New Course")} {initial ? formData.title : ""}</h1>

            <form className="flex flex-col gap-y-2 p-4 rounded border border-gray-300 bg-gray-100 w-full" onSubmit={handleSubmit}>
                {/* course type toggler */}
                <div className="flex gap-2 items-center justify-between">
                    <span className="min-w-fit font-semibold text-lg">{translate("Academic / Professional" )}</span>
                    <Switch
                        checked={formData.type == 'professional'}
                        onChange={() => {
                            let updatedFormData = { ...formData }
                            updatedFormData.type = formData.type == 'professional' ? 'academic' : 'professional'

                            setFormData(updatedFormData)
                        }}
                        className={`bg-gray-200 border-gray-300 flex h-[30px] min-w-[60px] md:min-w-[250px] items-center rounded-full border shadow`}
                    >
                        <div className={`${formData.type == 'professional' ? 'translate-x-full' : 'translate-x-0'} w-1/2 h-full transition-all duration-500 text-gray-50 rounded-full bg-blue-500 hover:bg-blue-400 gap-x-1 flex justify-center items-center`}>
                            <span className={`${formData.type == 'professional' ? 'rotate-0' : '-rotate-[360deg]'} transition-all duration-500`}>
                                {
                                    formData.type == 'professional' ? <FaStar fontSize={20} />
                                        : <FaGraduationCap fontSize={20} />
                                }
                            </span>

                            <span className="text-sm capitalize truncate max-md:hidden">{formData.type == 'professional' ? translate('Professional' ) : translate('Academic' )}</span>
                        </div>
                    </Switch>
                </div>

                {/* title */}
                <div className="flex flex-col w-full">
                    <label htmlFor="title" className="text-start">{required.title}<span className="text-red-500 text-sm"> *</span></label>
                    <input
                        id="title"
                        type="text"
                        className="input-bar"
                        placeholder={translate("Add title for your course" )}
                        value={formData.title || ""}
                        onChange={handleChange}
                    />
                    {
                        errors.title &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.title )}
                        </small>
                    }
                </div>

                {/* file */}
                {
                    !initial &&
                    <div className="flex flex-col w-full text-start">
                        <label htmlFor="file">{required.file}<span className="text-red-500 text-sm"> *</span></label>
                        <input id="file" type="file" accept="image/*" className="input-bar" placeholder="Choose a suitable name for your plan.." onChange={e => {
                            const file = e.target.files[0];
                            setFormData({
                                ...formData,
                                file,
                            })

                            validateField('file', file)
                        }} />
                        {
                            errors.file &&
                            <small className='text-red-700 flex items-center gap-1'>
                                <MdErrorOutline /> {translate(errors.file )}
                            </small>
                        }
                    </div>
                }

                {
                    formData.type == 'academic' &&
                    <div className="flex gap-x-2 max-md:flex-col">
                        {/* educational system */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="educationalSystem" className="text-start">{required.educationalSystem}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='educationalSystem'
                                options={options?.educationalSystems}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.educationalSystems.find(op => op.value == formData.educationalSystem || op.value == formData.educationalSystem?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['educationalSystem']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, educationalSystem: op.value })
                                }}
                                placeholder={translate("Choose your course educational system.." )}
                            />
                            {
                                errors.educationalSystem &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.educationalSystem )}
                                </small>
                            }
                        </div>

                        {/* grade level */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="gradeLevel" className="text-start">{required.gradeLevel}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='gradeLevel'
                                options={options?.gradeLevels}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.gradeLevels.find(op => op.value == formData.gradeLevel || op.value == formData.gradeLevel?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['gradeLevel']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, gradeLevel: op.value })
                                }}
                                placeholder={translate("Choose your course grade level.." )}
                            />
                            {
                                errors.gradeLevel &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.gradeLevel )}
                                </small>
                            }
                        </div>
                    </div>
                }

                {
                    formData.type == 'professional' &&
                    <div className="flex gap-x-2 max-md:flex-col">
                        {/* pathway */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="pathway" className="text-start">{required.pathway}<span className="text-red-500 text-sm"> *</span></label>
                            <Select
                                id='pathway'
                                options={options?.pathways}
                                isSearchable
                                classNames={{ control: () => "select-input", container: () => "text-sm text-start" }}
                                value={options?.pathways.find(op => op.value == formData.pathway || op.value == formData.pathway?._id) || ""}
                                onChange={op => {
                                    let updatedErrors = { ...errors }
                                    delete updatedErrors['pathway']
                                    setErrors(updatedErrors)

                                    setFormData({ ...formData, pathway: op.value })
                                }}
                                placeholder={translate("Choose your course pathway.." )}
                            />
                            {
                                errors.pathway &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.pathway )}
                                </small>
                            }
                        </div>

                        {/* order */}
                        <div className="flex flex-col w-full">
                            <label htmlFor="order" className="text-start">{required.order}<span className="text-red-500 text-sm"> *</span></label>
                            <input
                                id="order"
                                type="number"
                                className="input-bar"
                                placeholder={translate("Add your course order.." )}
                                value={formData.order || ""}
                                onChange={handleChange}
                            />
                            {
                                errors.order &&
                                <small className='text-red-700 flex items-center gap-1'>
                                    <MdErrorOutline /> {translate(errors.order )}
                                </small>
                            }
                        </div>
                    </div>
                }

                {/* learningOutcomes */}
                <div className="flex flex-col w-full gap-y-2">
                    <div className="text-start font-semibold tex-lg">{required.learningOutcomes}<span className="text-red-500 text-sm"> *</span></div>
                    {
                        formData.learningOutcomes.map((lo, idx) => {
                            const handleLOFieldChange = e => {
                                const fieldId = e.target.id
                                const fieldName = fieldId.split(`lo-${idx}-`)[1]
                                const fieldValue = e.target.value

                                let updatedErrors = { ...errors }
                                delete updatedErrors[fieldId]

                                let updatedFormData = { ...formData }
                                updatedFormData.learningOutcomes[idx][fieldName] = fieldValue
                                setFormData(updatedFormData)

                                if (!fieldValue) {
                                    updatedErrors[fieldId] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} field is required!`
                                }
                                setErrors(updatedErrors)
                            }

                            const addNewCriteria = () => {
                                let updatedErrors = { ...errors }
                                delete updatedErrors[`lo-${idx}-performanceCriteria`]

                                let updatedFormData = { ...formData }
                                updatedFormData.learningOutcomes[idx].performanceCriteria.push({ description: "" })
                                setFormData(updatedFormData)

                                updatedErrors[`lo-${idx}-pc-${updatedFormData.learningOutcomes[idx].performanceCriteria.length - 1}-description`] = "Description field is required"
                                setErrors(updatedErrors)
                            }

                            const deleteLO = () => {
                                let updatedFormData = { ...formData }
                                updatedFormData.learningOutcomes.splice(idx, 1)
                                setFormData(updatedFormData)

                                let updatedErrors = { ...errors }
                                Object.keys(updatedErrors).forEach(key => {
                                    if (key.includes(`lo-${idx}`)) {
                                        delete updatedErrors[key]
                                    } else if (key.includes(`lo-${idx}`)) {
                                        let keyTokens = key.split('lo-')[1].split('-')
                                        let kloIdx = +keyTokens[0]
                                        let rest = keyTokens[1]

                                        if (kloIdx > idx) {
                                            updatedErrors[`lo-${kloIdx - 1}-${rest}`] = updatedErrors[key]
                                            delete updatedErrors[key]
                                        }
                                    }
                                })

                                if (updatedFormData.learningOutcomes.length == 0) {
                                    updatedErrors[`learningOutcomes`] = "You have to add 1 learning outcome at least!"
                                }

                                setErrors(updatedErrors)
                            }

                            // dev_console_log(formData.learningOutcomes);
                            return (
                                <div key={idx} className="flex flex-col gap-y-1 text-sm rounded border-dashed border border-gray-400 bg-gray-50 p-2 relative">
                                    {/* delete button */}
                                    <button className="danger-icon-btn absolute right-2 top-2" onClick={deleteLO}><MdDelete /></button>

                                    {/* statement */}
                                    <div className="flex flex-col w-full">
                                        <label htmlFor={`lo-${idx}-statement`} className="text-start">{translate(`Learning Outcome`)} {idx + 1} {translate(`Statement`)}<span className="text-red-500 text-sm"> *</span></label>
                                        <input value={lo.statement} id={`lo-${idx}-statement`} type="text" className="input-bar" placeholder={translate("Add your learning outcome statement.." )} onChange={handleLOFieldChange} />
                                        {
                                            errors[`lo-${idx}-statement`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`lo-${idx}-statement`] )}
                                            </small>
                                        }
                                    </div>

                                    {/* scope and domain */}
                                    <div className="flex gap-x-2 gap-y-1 max-md:flex-col">
                                        {/* scope */}
                                        <div className="flex flex-col w-full">
                                            <label htmlFor={`lo-${idx}-scope`} className="text-start">{translate(`Learning Outcome`)} {idx + 1} {translate(`Scope`)}<span className="text-red-500 text-sm"> *</span></label>
                                            <input value={lo.scope} id={`lo-${idx}-scope`} type="text" className="input-bar" placeholder={translate("Add your learning outcome scope.." )} onChange={handleLOFieldChange} />
                                            {
                                                errors[`lo-${idx}-scope`] &&
                                                <small className='text-red-700 flex items-center gap-1'>
                                                    <MdErrorOutline /> {translate(errors[`lo-${idx}-scope`] )}
                                                </small>
                                            }
                                        </div>

                                        {/* domain */}
                                        <div className="flex flex-col w-full">
                                            <label htmlFor={`lo-${idx}-domain`} className="text-start">{translate(`Learning Outcome`)} {idx + 1} {translate(`Domain`)}<span className="text-red-500 text-sm"> *</span></label>
                                            <input value={lo.domain} id={`lo-${idx}-domain`} type="text" className="input-bar" placeholder={translate("Add your learning outcome domain.." )} onChange={handleLOFieldChange} />
                                            {
                                                errors[`lo-${idx}-domain`] &&
                                                <small className='text-red-700 flex items-center gap-1'>
                                                    <MdErrorOutline /> {translate(errors[`lo-${idx}-domain`] )}
                                                </small>
                                            }
                                        </div>
                                    </div>

                                    {/* Performance Criteria */}
                                    <div className="flex flex-col gap-y-1">
                                        <div className="text-start font-semibold tex-lg">{translate(`Learning Outcome`)} {idx + 1} {translate(`Performance Criteria`)}<span className="text-red-500 text-sm"> *</span></div>
                                        {
                                            lo.performanceCriteria.map((c, cIdx) => {
                                                const handleCriteriaChange = (e) => {
                                                    const fieldId = e.target.id
                                                    const fieldName = fieldId.split(`lo-${idx}-pc-${cIdx}-`)[1]
                                                    const fieldValue = e.target.value

                                                    let updatedErrors = { ...errors }
                                                    delete updatedErrors[fieldId]

                                                    let updatedFormData = { ...formData }
                                                    updatedFormData.learningOutcomes[idx].performanceCriteria[cIdx][fieldName] = fieldValue
                                                    setFormData(updatedFormData)

                                                    if (!fieldValue) {
                                                        updatedErrors[fieldId] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} field is required!`
                                                    }
                                                    setErrors(updatedErrors)
                                                }

                                                const deleteCriteria = () => {
                                                    let updatedFormData = { ...formData }
                                                    updatedFormData.learningOutcomes[idx].performanceCriteria.splice(cIdx, 1)
                                                    setFormData(updatedFormData)

                                                    let updatedErrors = { ...errors }
                                                    Object.keys(updatedErrors).forEach(key => {
                                                        if (key.includes(`lo-${idx}-pc-${cIdx}`)) {
                                                            delete updatedErrors[key]
                                                        } else if (key.includes(`lo-${idx}-pc`)) {
                                                            let keyTokens = key.split('pc-')[1].split('-')
                                                            let kcIdx = +keyTokens[0]
                                                            let rest = keyTokens[1]

                                                            if (kcIdx > idx) {
                                                                updatedErrors[`lo-${idx}-pc-${kcIdx - 1}-${rest}`] = updatedErrors[key]
                                                                delete updatedErrors[key]
                                                            }
                                                        }
                                                    })

                                                    if (updatedFormData.learningOutcomes[idx].performanceCriteria.length == 0) {
                                                        updatedErrors[`lo-${idx}-performanceCriteria`] = "You have to add 1 criteria at least!"
                                                    }
                                                    setErrors(updatedErrors)
                                                }

                                                // dev_console_log(errors);
                                                return (
                                                    <div key={cIdx} className="flex flex-col w-full p-2 rounded border border-dashed bg-gray-100 border-gray-400">
                                                        {/* criteria description */}
                                                        <div className="flex gap-x-2 justify-between">
                                                            <label htmlFor={`lo-${idx}-pc-${cIdx}-description`} className="text-start">{translate(`Learning Outcome`)} {idx + 1} {translate("Citeria")} {cIdx + 1} {translate("Description")}<span className="text-red-500 text-sm"> *</span></label>
                                                            <button className="danger-icon-btn" onClick={deleteCriteria}><MdDelete /></button>
                                                        </div>
                                                        <input value={c.description} id={`lo-${idx}-pc-${cIdx}-description`} type="text" className="input-bar" placeholder={translate("Add your citeria description.." )} onChange={handleCriteriaChange} />
                                                        {
                                                            errors[`lo-${idx}-pc-${cIdx}-description`] &&
                                                            <small className='text-red-700 flex items-center gap-1'>
                                                                <MdErrorOutline /> {translate(errors[`lo-${idx}-pc-${cIdx}-description`] )}
                                                            </small>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }

                                        {/* new criteria button */}
                                        <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-100 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={addNewCriteria}>
                                            <FaPlus />
                                            {translate("New Critria" )}
                                        </button>

                                        {
                                            errors[`lo-${idx}-performanceCriteria`] &&
                                            <small className='text-red-700 flex items-center gap-1'>
                                                <MdErrorOutline /> {translate(errors[`lo-${idx}-performanceCriteria`] )}
                                            </small>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }

                    {/* new lo button */}
                    <button type="button" className="rounded p-2 border border-dashed border-gray-400 bg-gray-50 hover:bg-gray-200 flex items-center gap-x-2 duration-300 transition-colors" onClick={() => {
                        let updatedErrors = { ...errors }
                        delete updatedErrors.learningOutcomes

                        let updatedFormData = { ...formData }
                        updatedFormData.learningOutcomes.push({ statement: "", scope: "", domain: "", performanceCriteria: [] })

                        updatedErrors[`lo-${updatedFormData.learningOutcomes.length - 1}-statement`] = "Statement field is required"
                        updatedErrors[`lo-${updatedFormData.learningOutcomes.length - 1}-scope`] = "Scope field is required"
                        updatedErrors[`lo-${updatedFormData.learningOutcomes.length - 1}-domain`] = "Domain field is required"
                        updatedErrors[`lo-${updatedFormData.learningOutcomes.length - 1}-performanceCriteria`] = "You have to add 1 criteria at least!"

                        setFormData(updatedFormData)
                        setErrors(updatedErrors)
                    }}>
                        <FaPlus />
                        {translate("New Learning Outcome" )}
                    </button>

                    {
                        errors.learningOutcomes &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.learningOutcomes )}
                        </small>
                    }
                </div>

                {/* create button */}
                <button className="submit-btn w-full mt-4" disabled={loading || Object.values(errors).some(v => v)}>
                    {
                        loading ?
                            <span className='flex justify-center text-gray-50 text-[24px]'>
                                <AiOutlineLoading3Quarters className='animate-spin' />
                            </span>
                            : <span className='capitalize'>{translate(initial ? 'Save Updates' : 'Create Course' )}</span>
                    }
                </button>
            </form>
        </div>
    )
}

export default CourseForm