"use client"

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { Fragment } from "react"
import IoEllipsisVertical from "@react-icons/all-files/io5/IoEllipsisVertical"
import {MdDelete} from "@react-icons/all-files/md/MdDelete"
import {MdEdit} from "@react-icons/all-files/md/MdEdit"
import MdReport from "@react-icons/all-files/md/MdReport"
import { useSelector } from "react-redux"
import { getUser } from "@lib/reducers/userReducer"
import { useTranslate } from "@hooks"


const PostOptions = ({ p, handleEditPost, handleDeletePost, handleReportPost }) => {
    const user = useSelector(getUser)
    const translate = useTranslate()

    return (
        <Menu>
            <MenuButton className="text-gray-500 p-2 rounded-full hover:bg-gray-200 transition-colors duration-300 text-[18px]">
                <IoEllipsisVertical />
            </MenuButton>
            <MenuItems transition anchor="bottom end" className="menu-3dots">
                {
                    (p.user._id == user.id || user.permessionLevel > 2) &&
                    <Fragment>
                        <MenuItem as={'button'} onClick={handleEditPost} className="menu-item-3dots">
                            <MdEdit />
                            <span>{translate(`Edit`)}</span>
                        </MenuItem>

                        <MenuItem as={'button'} onClick={handleDeletePost} className="menu-item-3dots">
                            <MdDelete />
                            <span>{translate(`Delete`)}</span>
                        </MenuItem>
                    </Fragment>
                }

                {
                    (p.user._id != user.id || user.permessionLevel > 2) &&
                    <MenuItem as={'button'} onClick={handleReportPost} className="menu-item-3dots">
                        <MdReport />
                        <span>{translate(`Report`)}</span>
                    </MenuItem>
                }
            </MenuItems>
        </Menu>
    )
}

export default PostOptions