"use client"
import React from 'react'
import { CustomModal } from "@components";
import { dev_console_log } from "@utils";

const AnnouncementModal = (props) => {
    const written_date = props?.announcement_written_date;
    // dev_console_log("AnnouncementModal")
    // dev_console_log("written_date")
    // dev_console_log(written_date)
    return (
        <CustomModal open={props?.open} onClose={props.onClose} className={`max-w-3xl`}>

            <div className="p-8 max-md:px-4">

                {
                    // main body
                }
                <div
                    className="p-8 max-md:px-4  gap-4"
                    style={{
                        overflowY: "auto",
                        height: "70vh",
                    }}
                >

                    <h2 className="text-xl font-bold">{props?.announcementTitle}</h2>
                    {
                        written_date ? (
                            <div
                                style={{
                                    color: "#5f5f5f",
                                    textAlign: "center",
                                    margin: "10px 0",
                                }}
                            >
                                {written_date}
                            </div>
                        )
                            :
                            null
                    }
                    <p className="text-gray-700">{props?.announcementText}</p>

                </div>

            </div>
        </CustomModal>
    )
}

export default AnnouncementModal