import userReducer from "./userReducer";
import langReducer from "./langReducer";
import dictReducer from "./dictReducer";
import socketReducer from "./socketReducer";
import announcementsReducer from "./announcementsReducer";

export {
    userReducer,
    langReducer,
    dictReducer,
    announcementsReducer,
    socketReducer
}