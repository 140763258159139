"use client"

import { useState } from "react"
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import IoIosSend from "@react-icons/all-files/io/IoIosSend"
import IoMdCloseCircle from "@react-icons/all-files/io/IoMdCloseCircle"


const CommentForm = ({ initial, handleFormAction, closeForm, postSeoTxt }) => {
    const [content, setContent] = useState(initial?.content || "")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true)
        await handleFormAction(content)
        setLoading(false)

        closeForm()
    }

    return (
        <form onSubmit={handleSubmit} className="flex gap-x-2 justify-between items-end w-full relative">
            <button
                className="danger-icon-btn absolute top-0 right-0"
                onClick={closeForm}
            >
                <IoMdCloseCircle />
            </button>

            {/* content */}
            <textarea value={content} onChange={e => setContent(e.target.value)} className={`${window.location.pathname.includes(postSeoTxt) ? 'bg-gray-100' : 'bg-gray-50'} w-full h-[60px] rounded border border-gray-300 p-2 text-sm`} />

            <button className="icon-btn text-[20px]" disabled={loading || !content}>
                {
                    loading ? <AiOutlineLoading3Quarters className="animate-spin" />
                        : <IoIosSend />
                }
            </button>
        </form>
    )
}


export default CommentForm