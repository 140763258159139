"use client"

import { useEffect, useState, Fragment } from "react"
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { AxiosRequest , dev_console_log } from "@utils";
import { BACKEND_ROOT } from "@constants"
import { useTranslate } from "@hooks";
import colors from 'tailwindcss/colors'
import {IoTimer} from "@react-icons/all-files/io5/IoTimer";
import {HiBadgeCheck} from "@react-icons/all-files/hi/HiBadgeCheck";
import {AiFillFire} from "@react-icons/all-files/ai/AiFillFire";
import {GiFrozenOrb} from "@react-icons/all-files/gi/GiFrozenOrb";
import { Calendar } from 'calendar';
import { useDispatch } from "react-redux"
import { setUserStatistics } from "@lib/reducers/userReducer"



const StreakProgress = () => {
    const translate = useTranslate();
    const [streakInfo, setStreakInfo] = useState(null)
    const dispatch = useDispatch()


    useEffect(() => {
        AxiosRequest.get(`${BACKEND_ROOT}/user/getStreakProgress`)
            .then(res => {
                setStreakInfo(res.data)

                const { daysCount, freezeChances } = res.data.userStreak
                dispatch(setUserStatistics({ streakDays: daysCount, freezeChances }))
            }).catch(err => {
                console.error(err)
            })
    }, [])


    const getWeekStreakDays = () => {
        // Create a calendar where the week starts on Saturday (6 means Saturday)
        const calendar = new Calendar(6);

        // Get the current date
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();       // Months are 0-based, so 0 = January
        const day = currentDate.getDate()

        // Get the calendar for the current month & week
        const monthDays = calendar.monthDays(year, month);
        const weekDays = monthDays.find(week => week.includes(day))

        return weekDays.map(day => new Date(year, month, day))
    }


    const isStreakDay = (givenDate) => {
        givenDate.setHours(0, 0, 0, 0)
        
        const lastStreakDay = new Date(streakInfo.userStreak.lastStreakUp)
        lastStreakDay.setHours(0, 0, 0, 0)

        // Calculate the start date of the streak
        const firstStreakDay = new Date(lastStreakDay);
        firstStreakDay.setDate(lastStreakDay.getDate() - (streakInfo.userStreak.daysCount - 1)); // Subtract the number of days in the streak minus one
    
        // Check if the given date is within the streak range
        return givenDate >= firstStreakDay && givenDate <= lastStreakDay;
    }

    
    return (
        <div className="flex flex-col p-8 gap-y-2">
            {
                !streakInfo ? <AiOutlineLoading3Quarters className="animate-spin text-blue-500 w-[15%] h-fit mx-auto" />
                    :
                    <Fragment>
                        <div className="flex items-center justify-center w-full relative">
                            <div className="flex items-center gap-x-1 absolute top-0 right-0 text-sky-400 font-semibold">
                                <GiFrozenOrb className="h-[30px] w-fit" />
                                <h1 className="text-lg">{streakInfo.userStreak.freezeChances}</h1>
                            </div>

                            <div className="relative">
                                <AiFillFire className="w-[150px] h-fit text-orange-400/70" />
                                <h1 className={`text-3xl font-semibold capitalize absolute inset-x-0 w-[60%] mx-auto bottom-[30%] text-gray-50`}>
                                    {streakInfo.userStreak.daysCount}
                                </h1>
                            </div>

                            <div className="flex flex-col gap-y-2">
                                <h1 className="text-start capitalize text-3xl font-semibold text-orange-400">{translate("streak days")}</h1>
                                <p className="italic text-lg text-gray-500">
                                    <span className="capitalize">{translate("Spend")} {streakInfo.streakDailyTarget} </span>
                                    {translate("minutes everyday to build your streak!")}
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-wrap gap-x-2">
                            {
                                getWeekStreakDays().map((dayDate, idx) => {
                                    const streakDay = isStreakDay(dayDate)
                                    const frostDay = streakInfo.userStreak.frostDays.some(d => new Date(d).toLocaleDateString() == new Date(dayDate).toLocaleDateString())

                                    return (
                                        <div key={idx} className="mx-auto flex flex-col">
                                            <h1 className="text-gray-400 font-semibold text-lg">{dayDate.toLocaleDateString('eg', { weekday: 'short' })}</h1>
                                            {
                                                frostDay ? <GiFrozenOrb className={`h-[40px] w-fit text-sky-400`} />
                                                    : <HiBadgeCheck className={`h-[40px] w-fit ${streakDay ? "text-orange-400" : "text-gray-400"}`} />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className="mx-auto max-w-[250px] w-full">
                            <CircularProgressbarWithChildren
                                maxValue={streakInfo.streakDailyTarget}
                                value={streakInfo.userTodayProgress}
                                styles={buildStyles({
                                    // Rotation of path and trail, in number of turns (0-1)
                                    rotation: 0,

                                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                    strokeLinecap: 'round',

                                    // How long animation takes to go from one percentage to another, in seconds
                                    pathTransitionDuration: 1,

                                    // Can specify path transition in more detail, or remove it entirely
                                    pathTransition: 'linear',

                                    // Colors
                                    pathColor: streakInfo.userTodayProgress >= streakInfo.streakDailyTarget ? colors.green[400] : colors.orange[400],
                                    trailColor: colors.gray[200],
                                })}
                            >
                                <div className={`flex flex-col items-center gap-y-2 ${streakInfo.userTodayProgress >= streakInfo.streakDailyTarget ? 'text-green-400' : 'text-orange-400'}`}>
                                    <h1 className="font-semibold capitalize text-2xl">
                                        {translate(`Today's`)}
                                    </h1>

                                    {
                                        streakInfo.userTodayProgress >= streakInfo.streakDailyTarget ?
                                        <HiBadgeCheck className="w-[50px] h-fit" />
                                        : <IoTimer className="w-[50px] h-fit" />
                                    }
                                    <p className="font-semibold italic capitalize text-2xl">
                                        {streakInfo.userTodayProgress} {translate("minutes")}
                                    </p>
                                </div>
                            </CircularProgressbarWithChildren>
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default StreakProgress