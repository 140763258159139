"use client"

import { useMemo } from "react"
import { useTranslate } from "@hooks"


const Heatmap = ({ legend, labels, levels, actions = [] }) => {
    const translate = useTranslate()

    const dynamic = useMemo(() => {
        const colsCount = labels.length + 1
        const width = `${colsCount * 80}px`
        const gridCols = `grid-cols-${colsCount}`

        return { colsCount, width, gridCols }
    }, [labels, levels])


    const legends = {
        performance: [
            { name: "Not Tested, yet!", bgColor: 'bg-gray-400', textColor: "text-gray-400" },
            { name: "Needs Improvement", bgColor: 'bg-red-400', textColor: "text-red-400" },
            { name: "Basic Level", bgColor: 'bg-yellow-400', textColor: "text-yellow-400" },
            { name: "Developing Level", bgColor: 'bg-orange-400', textColor: "text-orange-400" },
            { name: "Proficient Level", bgColor: 'bg-sky-400', textColor: "text-sky-400" },
            { name: "Partial Mastery Level", bgColor: 'bg-blue-400', textColor: "text-blue-400" },
            { name: "Near Mastery Level", bgColor: 'bg-green-400', textColor: "text-green-400" },
            { name: "Mastery Level", bgColor: 'bg-lime-400', textColor: "text-lime-400" },
        ],
        cognitive: [
            { name: "Not Tested, yet!", bgColor: 'bg-gray-400', textColor: "text-gray-400" },
            { name: "Needs Improvement", bgColor: 'bg-red-400', textColor: "text-red-400" },
            { name: "Remember", bgColor: 'bg-yellow-400', textColor: "text-yellow-400" },
            { name: "Understand", bgColor: 'bg-orange-400', textColor: "text-orange-400" },
            { name: "Apply", bgColor: 'bg-sky-400', textColor: "text-sky-400" },
            { name: "Analyze", bgColor: 'bg-blue-400', textColor: "text-blue-400" },
            { name: "Evaluate", bgColor: 'bg-green-400', textColor: "text-green-400" },
            { name: "Create", bgColor: 'bg-lime-400', textColor: "text-lime-400" },
        ]
    }

    return (
        <section className={`flex flex-col gap-y-2 w-full max-w-fit`}>
            {/* legend */}
            <div className="grid grid-cols-4 gap-1">
                {
                    legends[legend].map((level, idx) => {

                        return (
                            <div key={idx} className="flex items-center gap-x-1">
                                <span className={`${level.bgColor} h-[15px] w-[15px]`} />
                                <span className={`${level.textColor} text-xs truncate`}>{translate(level.name)}</span>
                            </div>
                        )
                    })
                }
            </div>

            {/* chart */}
            <div
                style={{ 'gridTemplateColumns': `repeat(${dynamic.colsCount}, minmax(0, 1fr))`, minWidth: dynamic.width }}
                className={`grid w-fit divide-x divide-y divide-gray-300 border-b border-r border-gray-300`}
            >
                <span className="text-xs p-2 min-w-[80px] h-[80px] flex items-center border-l border-t border-gray-300">Learning Outcome</span>
                {
                    labels.map((label, idx) => {

                        return <button onClick={actions[idx]} key={idx} className="p-2 min-w-[80px] h-[80px] text-start text-xs overflow-y-auto small-scroll">{translate(label)}</button>
                    })
                }


                <span className="text-xs p-2 min-w-[80px] h-[80px] flex items-center capitalize">{translate(legend)}</span>
                {
                    levels.map((level, idx) => {
                        const bgColor = legends[legend][level].bgColor
                        return <button onClick={actions[idx]} key={idx} className={`p-2 min-w-[80px] h-[80px] ${bgColor}`} />
                    })
                }

            </div>
        </section>
    )
}

export default Heatmap