"use client"

import { useEffect, useState, useRef } from "react";
import { boxedTree } from "d3-mitch-tree";
import "d3-mitch-tree/dist/css/d3-mitch-tree-theme-default.min.css";
import "d3-mitch-tree/dist/css/d3-mitch-tree.min.css";
import { withStore } from "@components"
import { useTranslate } from "@hooks"

const CourseMindMap = ({ course }) => {
    const mindMapRef = useRef(null);
    const translate = useTranslate()

    useEffect(() => {
        // Create the tree visualization
        new boxedTree()
            .setData(course.mindMap)
            .setElement(mindMapRef.current)
            .setIdAccessor(node => node.id)
            .setChildrenAccessor(node => node.children)
            .setTitleDisplayTextAccessor(node => translate(node.name))
            .setBodyDisplayTextAccessor(node => translate(node.description))
            .initialize();
    }, [course.mindMap]);

    return <div ref={mindMapRef} className="w-full h-screen" />;
}


export default withStore(CourseMindMap);


// react flow
// import ReactFlow, { MiniMap, Controls, Background } from 'reactflow';
// import 'reactflow/dist/style.css';

// const CourseMindMap = ({ course }) => {
//     const [mindMap, setMindMap] = useState({ nodes: [], edges: [] });


//     // Transform the course data into a structure suitable for React Flow
//     const buildMindMap = (root) => {
//         const nodes = [];
//         const edges = [];
    
//         const step = 150; // Spacing between nodes
    
//         // Initialize root node position
//         let rootX = 0;
//         let rootY = 0;
    
//         // Add the root node
//         nodes.push({
//             id: root.id,
//             data: { label: root.name },
//             position: { x: rootX, y: rootY },
//         });

//         // Traverse through learning outcomes
//         const numLos = root.children.length
//         root.children.forEach((lo, loIdx) => {
//             const loX = rootX + step * (numLos - loIdx - 1);
//             const loY = rootY + (loIdx + 1) * step;
    
//             // Add learning outcome node
//             nodes.push({
//                 id: lo.id,
//                 data: { label: lo.name },
//                 position: { x: loX, y: loY },
//             });
    
//             // Create edge from root to learning outcome
//             edges.push({
//                 id: `${root.id}-${lo.id}`,
//                 source: root.id,
//                 target: lo.id,
//                 animated: true,
//             });
    
//             // Traverse through learning targets (lo.children)
//             const numLts = lo.children.length
//             lo.children.forEach((lt, ltIdx) => {
//                 const ltX = loX + step * (numLts - ltIdx - 1); 
//                 const ltY = loY + step;
    
//                 // Add learning target node
//                 nodes.push({
//                     id: lt.id,
//                     data: { label: lt.name },
//                     position: { x: ltX, y: ltY },
//                 });
    
//                 // Create edge from learning outcome to learning target
//                 edges.push({
//                     id: `${lo.id}-${lt.id}`,
//                     source: lo.id,
//                     target: lt.id,
//                     animated: true,
//                 });
//             });
//         });
    
//         return { nodes, edges };
//     };
    
    
    
//     useEffect(() => {
//         const root = {
//             id: course._id,
//             name: course.title,
//             children: course.learningOutcomes.map((lo) => {
//                 return {
//                     id: lo._id,
//                     name: lo.statement,
//                     children: lo.performanceCriteria.map((lt) => {
//                         return {
//                             id: lt._id,
//                             name: lt.description,
//                         };
//                     }),
//                 };
//             }),
//         };

//         const { nodes, edges } = buildMindMap(root);
//         setMindMap({ nodes, edges }); // Set the elements state
//     }, [course]);


//     return (
//         <div className="h-screen w-full">
//             <ReactFlow
//                 nodes={mindMap.nodes}
//                 edges={mindMap.edges}
//                 fitView
//                 fitViewOptions={{ padding: 0.2 }}
//             >
//                 <MiniMap />
//                 <Controls />
//                 <Background />
//             </ReactFlow>
//         </div>
//     )
// }