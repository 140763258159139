"use client"

import { useTranslate } from "@hooks";
import { Fragment, useState } from "react";
import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import { MdErrorOutline } from "@react-icons/all-files/md/MdErrorOutline";
import { FaImage } from "@react-icons/all-files/fa/FaImage";
import { FaUpload } from "@react-icons/all-files/fa/FaUpload";
import RichBox from "./RichBox";


const RankRewardForm = ({ initial, handleFormAction }) => {
  const translate = useTranslate()
  const { image, description, ...rest } = initial || {}
  const [imageSrc, setImageSrc] = useState(image || null)
  const [descriptionValue, setDescriptionValue] = useState(description || "")
  const [formData, setFormData] = useState(rest || null)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)

  const required = {
    "name": translate("Reward Name"),
    "rank": translate("Reward Rank"),
    "year": translate("Reward Year"),
    ...(!image && { "file": translate("Reward Image") }),
  }

  const validationRules = {
    name: { invalid: (value) => value.length < 3, error: "Must be 3 letters at least!" },
    description: { invalid: (value) => value.length < 100, error: "Must be 100 characters at least!" },
    rank: { invalid: (value) => !/^[0-9]+$/.test(value) || value < 1, error: "Must be a number greater than 0!" },
    year: { invalid: (value) => !/^[0-9]+$/.test(value) || value < 1900 || value > 2100, error: "Must be a number between 1900 and 2100!" },
    file: { invalid: (value) => !value?.type?.startsWith('image/'), error: "Must be image file (jpg, png, etc..)!" },
  }


  const validateField = (fieldName, fieldValue) => {
    let updatedErrors = { ...errors }
    delete updatedErrors[fieldName]

    const isInvalid = validationRules[fieldName].invalid(fieldValue)

    if (isInvalid) {
      updatedErrors[fieldName] = validationRules[fieldName].error
    }

    setErrors(updatedErrors)

    return isInvalid
  }


  const handleChange = (e) => {
    const fieldName = e.target.id
    const fieldValue = e.target.value

    setFormData({
      ...formData,
      [fieldName]: fieldValue,
    })

    validateField(fieldName, fieldValue)
  }


  const handleSubmit = async e => {
    e.preventDefault()

    await handleFormAction({...formData, description: descriptionValue}, setErrors, required, setLoading);
  }

  return (
    <div className="flex flex-col justify-center items-center gap-y-2 w-full">
      <h1 className="text-2xl font-semibold text-blue-500 capitalize">{translate(initial ? `Update Rank Reward` : "Create New Rank Reward")}</h1>

      <form className="flex flex-col gap-y-2 p-4 rounded border border-gray-300 bg-gray-100 w-full" onSubmit={handleSubmit}>
        {/* name */}
        <div className="flex flex-col w-full text-start">
          <label htmlFor="name" className="capitalize">{required.name}<span className="text-red-500 text-sm"> *</span></label>
          <input value={formData.name || ""} id="name" type="text" className="input-bar" placeholder={translate("Choose a suitable name for your Rank Reward..")} onChange={handleChange} />
          {
            errors.name &&
            <small className='text-red-700 flex items-center gap-1'>
              <MdErrorOutline /> {translate(errors.name)}
            </small>
          }
        </div>

        <div className="flex max-md:flex-col gap-2">
          {/* rank */}
          <div className="flex flex-col w-full text-start">
            <label htmlFor="rank" className="capitalize">{required.rank}<span className="text-red-500 text-sm"> *</span></label>
            <input value={formData.rank || 0} id="rank" type="number" min={1} className="input-bar" placeholder="must be greater than 0" onChange={handleChange} />
            {
              errors.rank &&
              <small className='text-red-700 flex items-center gap-1'>
                <MdErrorOutline /> {translate(errors.rank)}
              </small>
            }
          </div>

          {/* year */}
          <div className="flex flex-col w-full text-start">
            <label htmlFor="year" className="capitalize">{required.year}<span className="text-red-500 text-sm"> *</span></label>
            <input value={formData.year || 0} id="year" type="number" min={1900} max={2100} className="input-bar" placeholder="YYYY" onChange={handleChange} />
            {
              errors.year &&
              <small className='text-red-700 flex items-center gap-1'>
                <MdErrorOutline /> {translate(errors.year)}
              </small>
            }
          </div>
        </div>

        {/* file */}
        <div className="flex flex-col w-full text-start">
          <h1 className="capitalize">{translate("Reward Image")}<span className="text-red-500 text-sm"> *</span></h1>

          <div className="relative w-full h-[200px] overflow-hidden">
            {
              imageSrc ?
                <img src={imageSrc} className="w-full h-full object-cover rounded-lg" />
                :
                <div className="flex flex-col w-full h-full justify-center items-center text-gray-500 border-2 border-dashed border-gray-400 rounded-lg">
                  <FaImage className="w-full h-full p-[5%]" />
                </div>
            }

            <label htmlFor="file" className={`rounded-lg ${errors.file || loading ? "opacity-80" : "opacity-0 hover:opacity-80"} ${loading ? "cursor-wait" : "cursor-pointer"} absolute flex flex-col gap-y-2 justify-center items-center bg-black/40 text-gray-50 inset-0 transition-all duration-300`}>
              {
                loading ? <AiOutlineLoading3Quarters className="animate-spin w-fit h-1/3" />
                  : errors.file ?
                    <Fragment>
                      <MdErrorOutline className="w-fit h-1/3 text-red-600" />
                      <span className="text-center font-semibold text-sm uppercase text-red-600">{translate(errors.file)}</span>
                    </Fragment>
                    :
                    <Fragment>
                      <FaUpload className="w-fit h-1/3" />
                      <span className="capitalize font-semibold">{translate(imageSrc ? 'change' : 'add')} {translate("image")}</span>
                    </Fragment>
              }
            </label>

            <input id="file" type="file" accept="image/*" hidden disabled={loading} onChange={e => {
              const file = e.target.files[0];

              if (!Boolean(file)) return;

              setFormData({
                ...formData,
                file,
              })

              const isInvalid = validateField('file', file)

              if (!isInvalid) {
                const tempURL = URL.createObjectURL(file);
                setImageSrc(tempURL);
              }
            }} />
          </div>
        </div>

        {/* description */}
        <div className="flex flex-col w-full text-start">
          <label htmlFor="description" className="capitalize">{translate("Reward description")}<span className="text-gray-500 text-sm italic"> {`(${translate("optional")})`}</span></label>
          <RichBox
            id="description"
            defaultValue={descriptionValue}
            onChange={v => {
              setDescriptionValue(v)

              validateField('description', v)
            }} />
          {
            errors.description &&
            <small className='text-red-700 flex items-center gap-1'>
              <MdErrorOutline /> {translate(errors.description)}
            </small>
          }
        </div>

        {/* create button */}
        <button className="submit-btn w-full mt-4" disabled={loading || Object.values(errors).some(v => v)}>
          {
            loading ?
              <span className='flex justify-center text-gray-50 text-[24px]'>
                <AiOutlineLoading3Quarters className='animate-spin' />
              </span>
              : <span className='capitalize'>{translate(initial ? 'Save Updates' : 'Create Rank Reward')}</span>
          }
        </button>
      </form>
    </div>
  )
}

export default RankRewardForm