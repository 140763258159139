import translateServer from '@utils_backend_only/serverside.translation';
import Link from "next/link";
import { Fragment } from "react";

const ServerCourseCard = ({ course, lang }) => {

    return (
        <div className="flex flex-col rounded-lg shadow bg-gray-100 border border-gray-300 overflow-hidden w-[400px] mx-auto">
            <div className="relative hover:scale-110 transition-all duration-500">
                <img src={course.image || "/logo-2.png"} className="h-[150px] w-full object-cover" />
                <Link href={`/${lang}/course/${course.seoTxt}`} className="absolute inset-0 hover:bg-opacity-40 bg-black bg-opacity-0 transition-all duration-500" />
            </div>

            <div className="flex flex-col p-4 gap-y-4">
                <h1 className="font-bold text-2xl text-blue-400">{translateServer(course.title, lang)}</h1>

                <div className="grid grid-cols-2 gap-x-2 font-semibold text-gray-400">
                    {
                        course.type == 'academic' &&
                        <Fragment>
                            <h2>{translateServer(`Grade Level`, lang)}:</h2>
                            <h2>{translateServer(course.gradeLevel.name, lang)}</h2>

                            <h2>{translateServer(`Educational System:`, lang)}:</h2>
                            <h2>{translateServer(course.educationalSystem.name, lang)}:</h2>
                        </Fragment>
                    }

                    {
                        course.type == 'professional' &&
                        <Fragment>
                            <h2>{translateServer(`Pathway`, lang)}:</h2>
                            <h2>{translateServer(course.pathway.name, lang)}</h2>

                            <h2>{translateServer(`Order in Pathway`, lang)}:</h2>
                            <h2>{course.order}</h2>
                        </Fragment>
                    }
                </div>

                {/* enroll */}
                <Link className='submit-btn' href={`/${lang}/course/${course.seoTxt}`}>{translateServer(`Enroll Now`, lang)}</Link>
            </div>
        </div>
    )
}
export default ServerCourseCard