"use client"

import { getUser, setUser, setUserStatistics } from "@lib/reducers/userReducer"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "@hooks";
import { AxiosRequest, formatNumber, fib, calcLevel, dev_console_log, lowerCaseUnderscoredString } from "@utils";
import { BACKEND_ROOT } from "@constants";
import { clone } from "lodash";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { CalenderComponent, CustomModal, ProgressCourseCard } from "@components";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import en from 'react-phone-number-input/locale/en';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import colors from 'tailwindcss/colors'

import CustomBootstrap from '../styles/CustomBootstrap.module.css';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
// import * as solidIcons from '@fortawesome/free-solid-svg-icons';
// console.log("solidIcons fortawesome/free-solid-svg-icons");
// console.log(solidIcons);
// console.log(Object.values(solidIcons));

// library.add(...Object.values(solidIcons));



import { faAnglesUp } from '@fortawesome/free-solid-svg-icons';

// console.log("faHouse fortawesome/free-solid-svg-icons");
// console.log(faHouse);

library.add(faAnglesUp);
// library.add(...all);

const Streak_Checkpoint = "Streak Checkpoint";

const fontawesome_type = "fontawesome";
const svg_placeholder = "svg+placeholder";
const imageUrl = "imageUrl";

const from_another_table = "from_another_table";


const BadgeSingleComp = ({ parent_is, badge }) => {

    let text_col = 7;
    let text_col_sm = 9;
    let text_col_md = 12;
    let text_col_lg = 6;
    let text_col_xl = 8;

    let img_col = 5;
    let img_col_sm = 3;
    let img_col_md = 12;
    let img_col_lg = 6;
    let img_col_xl = 4;
    let shadow = "shadow";
    let width = "97%";
    switch (parent_is) {
        case "profile":
            text_col_md = 12;
            text_col_lg = 6;
            img_col_md = 12;
            img_col_lg = 6;

            text_col_xl = 8;
            img_col_xl = 4;

            text_col = 7;
            text_col_sm = 9;
            img_col = 5;
            img_col_sm = 3;

            shadow = "shadow";
            width = "97%";

            break;

        case "ach_antd_table":
            text_col_md = 11;
            text_col_lg = 10;
            img_col_md = 1;
            img_col_lg = 2;

            text_col_xl = 11;
            img_col_xl = 1;

            text_col = 7;
            text_col_sm = 10;
            img_col = 5;
            img_col_sm = 2;

            shadow = "";
            width = "100%";

            break;

        default:
            break;
    }
    let tmp_tooltip = null;
    if (badge?.tooltipCached) {
        tmp_tooltip = badge?.tooltipCached;
    } else if (badge?.Badge?.tooltipCached) {
        tmp_tooltip = badge?.Badge?.tooltipCached;
    }

    // const generated_dirname = process.env.NEXT_PUBLIC_BACKEND_DIR + "/ach_badge/generated/" + lowerCaseUnderscoredString(badge?.Badge?.type ?? "");
    const generated_dirname = "/ach_badge/generated/" + lowerCaseUnderscoredString(badge?.Badge?.type ?? "");
    // maybe have an img here in case there is an error
    let tmp_src = generated_dirname;

    // dev_console_log("badge?.Badge?.iconType");
    // dev_console_log(badge?.Badge?.iconType);
    // dev_console_log("badge?.Badge");
    // dev_console_log(badge?.Badge);
    // dev_console_log("badge");
    // dev_console_log(badge);



    return (
        <Tooltip
            title={tmp_tooltip}
            placement="top"
            arrow
            TransitionComponent={Zoom}
            describeChild
        >
            {
                (() => {

                    switch (badge?.Badge?.iconType) {
                        case fontawesome_type:
                            tmp_src = badge?.Badge?.icon?.fontawesome ?? "";
                            const tmp_color = badge?.Badge?.icon?.color;
                            return (
                                <FontAwesomeIcon
                                    icon={`fa-solid ${tmp_src}`}
                                    style={{
                                        width: "100%",
                                        height: ("dashboard" === parent_is) ? "33px" : "auto",
                                        color: tmp_color ?? "#3b82f6",
                                    }}
                                />
                            );
                        case svg_placeholder:
                            if (badge?.iconCached) {
                                tmp_src += badge?.iconCached;
                            } else if (badge?.Badge?.iconCached) {
                                tmp_src += badge?.Badge?.iconCached;
                            }
                            tmp_src = `${tmp_src}.svg`;
                            return (
                                <img
                                    src={tmp_src}
                                    alt={tmp_tooltip ?? ""}
                                    style={{
                                        width: "100%",
                                        height: ("dashboard" === parent_is) ? "33px" : "auto",
                                    }}
                                />
                            );
                        case from_another_table:
                            if (badge?.iconCached) {
                                tmp_src = badge?.iconCached;
                            } else if (badge?.Badge?.iconCached) {
                                tmp_src = badge?.Badge?.iconCached;
                            }
                            return (
                                <img
                                    src={tmp_src}
                                    alt={tmp_tooltip ?? ""}
                                    style={{
                                        width: "100%",
                                        height: ("dashboard" === parent_is) ? "33px" : "auto",
                                    }}
                                />
                            );

                        default:
                            return null;
                    }


                })()
            }
        </Tooltip>
    );
}

export default BadgeSingleComp