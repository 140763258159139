"use client";

import { persistor, store } from "@lib/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { getDict, setDict } from "@lib/reducers/dictReducer";
import { getUser, setUser } from "@lib/reducers/userReducer";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Ensure this is correctly imported without curly braces.


const StoreProvider = ({ children }) => {

  const handleBeforeLift = async () => {
    const state = store.getState();

    // get dict
    const dict = await import("@cache/dict.json")

    // extract user
    const userToken = Cookies.get('access_token');
    const user = userToken ? jwtDecode(userToken).user : null;

    // Only set the dictionary and user in the state if they're not already initialized.
    if (!getDict(state)) store.dispatch(setDict(dict));
    if (!getUser(state) && user) store.dispatch(setUser(user));
  }

  return (
    <Provider store={store}>
      {
        <PersistGate persistor={persistor} loading={null} onBeforeLift={handleBeforeLift}>
          {children}
        </PersistGate>
      }
    </Provider>
  );
};

export const withStore = (Component) => {
  const WrappedComponent = (props) => (
    <StoreProvider>
      <Component {...props} />
    </StoreProvider>
  );

  WrappedComponent.displayName = `withStore(${Component.displayName || Component.name || 'Component'})`;
  return WrappedComponent;
};

export default StoreProvider;
