"use client"

import { useTranslate } from "@hooks";
import { useState } from "react";
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import {MdErrorOutline} from "@react-icons/all-files/md/MdErrorOutline";

const PlanForm = ({ initial, handleFormAction }) => {
    const translate = useTranslate()

    const [formData, setFormData] = useState(initial || {})
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false)
    const required = {
        "name": translate("Plan Name" ),
        "defaultDurationInMonth": translate("Default Duration (In Month)" ),
        "feesInEGP": translate("Fees (In EGP)" ),
    }

    const validationRules = {
        name: { invalid: (value) => value.length < 3, error: "Must be 3 letters at least!" },
        defaultDurationInMonth: { invalid: (value) => !/^[0-9]+$/.test(value) || value < 1, error: "Must be a number greater than 0!" },
        feesInEGP: { invalid: (value) => !/^[0-9]+$/.test(value) || value < 50, error: "Must be a number at least equal to 50!" },
    }


    const validateField = (fieldName, fieldValue) => {
        let updatedErrors = { ...errors }
        delete updatedErrors[fieldName]

        if (validationRules[fieldName].invalid(fieldValue)) {
            updatedErrors[fieldName] = validationRules[fieldName].error
        }

        setErrors(updatedErrors)
    }


    const handleChange = (e) => {
        const fieldName = e.target.id
        const fieldValue = e.target.value

        setFormData({
            ...formData,
            [fieldName]: fieldValue,
        })

        validateField(fieldName, fieldValue)
    }


    const handleSubmit = async e => {
        e.preventDefault()

        setLoading(true);
        await handleFormAction(formData, setErrors, required);
        setLoading(false);
    }

    return (
        <div className="flex flex-col justify-center items-center gap-y-2 ">
            <h1 className="text-2xl font-semibold text-blue-500">{translate(initial ? `Update Plan Details` : "Create New Plan" )}</h1>

            <form className="flex flex-col gap-y-2 p-4 rounded border border-gray-300 bg-gray-100 w-full" onSubmit={handleSubmit}>
                {/* name */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="name">{required.name}<span className="text-red-500 text-sm"> *</span></label>
                    <input value={formData.name || ""} id="name" type="text" className="input-bar" placeholder="Choose a suitable name for your plan.." onChange={handleChange} />
                    {
                        errors.name &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.name )}
                        </small>
                    }
                </div>
                {/* defaultDurationInMonth */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="defaultDurationInMonth">{required.defaultDurationInMonth}<span className="text-red-500 text-sm"> *</span></label>
                    <input value={formData.defaultDurationInMonth || 0} id="defaultDurationInMonth" type="number" min={1} className="input-bar" placeholder="Plan duration.." onChange={handleChange} />
                    {
                        errors.defaultDurationInMonth &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.defaultDurationInMonth )}
                        </small>
                    }
                </div>
                {/* feesInEGP */}
                <div className="flex flex-col w-full text-start">
                    <label htmlFor="feesInEGP">{required.feesInEGP}<span className="text-red-500 text-sm"> *</span></label>
                    <input value={formData.feesInEGP || 0} id="feesInEGP" type="number" min={0} className="input-bar" placeholder="Plan fees.." onChange={handleChange} />
                    {
                        errors.feesInEGP &&
                        <small className='text-red-700 flex items-center gap-1'>
                            <MdErrorOutline /> {translate(errors.feesInEGP )}
                        </small>
                    }
                </div>
                {/* create button */}
                <button className="submit-btn w-full mt-4" disabled={loading || Object.values(errors).some(v => v)}>
                    {
                        loading ?
                            <span className='flex justify-center text-gray-50 text-[24px]'>
                                <AiOutlineLoading3Quarters className='animate-spin' />
                            </span>
                            : <span className='capitalize'>{translate(initial ? 'Save Updates' : 'Create Plan' )}</span>
                    }
                </button>
            </form>
        </div>
    )
}

export default PlanForm