import Header from "./Header";
import Footer from "./Footer";
import StoreProvider from "./StoreProvider";
import Loading from "./Loading";
import FlyInOutTransition from "./FlyInOutTransition";
import AnimatedContent from "./AnimatedContent";
import Heatmap from "./Heatmap";
import ProgressCourseCard from "./ProgressCourseCard";
import CustomModal from "./CustomModal";
import LearningTimesForm from "./LearningTimesForm";
import LearnWithEveProgress from "./LearnWithEveProgress";
import CourseForm from "./CourseForm";
import PlanForm from "./PlanForm";
import StaffUserForm from "./StaffUserForm";
import ChildCard from "./ChildCard";
import CourseWork from "./CourseWork"
import CourseForum from "./Forum/CourseForum"
import CommentCard from "./Forum/CommentCard"
import CommentForm from "./Forum/CommentForm"
import LikesAndComments from "./Forum/LikesAndComments";
import PostPageOptions from "./Forum/PostPageOptions";
import PostForm from "./Forum/PostForm"
import RichBox from "./RichBox";
import PaymobPayment from "./PaymobPayment";
import FawaterakPayment from "./FawaterakPayment";
import NotificationsCenter from "./NotificationsCenter"
import CourseCard from "./CourseCard";
import ServerCourseCard from "./ServerCourseCard";
import EnrollCourseButton from "./EnrollCourseButton";
import { withStore } from "./StoreProvider";
import ShowMoreCoursesButton from "./ShowMoreCoursesButton";
import CalenderComponent from "./CalenderComponent";
import ActivityTracker from "./ActivityTracker"
import StreakProgress from "./StreakProgress"
import AchievementSingleComp from "./AchievementSingleComp"
import YearRanking from "./YearRanking";
import BadgeSingleComp from "./BadgeSingleComp";
import RankRewardForm from "./RankRewardForm";
import CoinsStore from "./CoinsStore";
import StoreItemForm from "./StoreItemForm";
import ProgressHeatmap from "./ProgressHeatmap";
import CourseMindMap from "./CourseMindMap";
import withSuspense from "./withSuspense";
import AnnouncementModal from "./AnnouncementModal";


export {
    AnnouncementModal,
    withSuspense,
    CourseMindMap,
    ProgressHeatmap,
    StoreItemForm,
    CoinsStore,
    RankRewardForm,
    BadgeSingleComp,
    YearRanking,
    AchievementSingleComp,
    StreakProgress,
    ActivityTracker,
    Header,
    Footer,
    StoreProvider,
    Loading,
    FlyInOutTransition,
    AnimatedContent,
    Heatmap,
    ProgressCourseCard,
    CustomModal,
    LearningTimesForm,
    LearnWithEveProgress,
    CourseForm,
    PlanForm,
    StaffUserForm,
    ChildCard,
    CourseWork,
    CourseForum,
    CommentCard,
    PostForm,
    CommentForm,
    RichBox,
    PaymobPayment,
    FawaterakPayment,
    NotificationsCenter,
    CourseCard,
    ServerCourseCard,
    EnrollCourseButton,
    withStore,
    ShowMoreCoursesButton,
    LikesAndComments,
    PostPageOptions,
    CalenderComponent,
}