"use client"

import { getUser, setUser, setUserStatistics } from "@lib/reducers/userReducer"
import { useDispatch, useSelector } from "react-redux"
import { useTranslate } from "@hooks";
import { AxiosRequest, formatNumber, fib, calcLevel, dev_console_log, lowerCaseUnderscoredString } from "@utils";
import { BACKEND_ROOT } from "@constants";
import { clone } from "lodash";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { CalenderComponent, CustomModal, ProgressCourseCard } from "@components";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import en from 'react-phone-number-input/locale/en';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import colors from 'tailwindcss/colors'

import CustomBootstrap from '../styles/CustomBootstrap.module.css';

import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
// import * as solidIcons from '@fortawesome/free-solid-svg-icons';
// console.log("solidIcons fortawesome/free-solid-svg-icons");
// console.log(solidIcons);
// console.log(Object.values(solidIcons));

// library.add(...Object.values(solidIcons));



import { faAnglesUp } from '@fortawesome/free-solid-svg-icons';

// console.log("faHouse fortawesome/free-solid-svg-icons");
// console.log(faHouse);

library.add(faAnglesUp);
// library.add(...all);

const Streak_Checkpoint = "Streak Checkpoint";

const fontawesome_type = "fontawesome";
const svg_placeholder = "svg+placeholder";
const imageUrl = "imageUrl";

const from_another_table = "from_another_table";


const AchievementSingleComp = ({ parent_is, achievement }) => {

    let text_col = 7;
    let text_col_sm = 9;
    let text_col_md = 12;
    let text_col_lg = 6;
    let text_col_xl = 8;

    let img_col = 5;
    let img_col_sm = 3;
    let img_col_md = 12;
    let img_col_lg = 6;
    let img_col_xl = 4;
    let shadow = "shadow";
    let width = "97%";
    switch (parent_is) {
        case "profile":
            text_col_md = 12;
            text_col_lg = 6;
            img_col_md = 12;
            img_col_lg = 6;

            text_col_xl = 8;
            img_col_xl = 4;

            text_col = 7;
            text_col_sm = 9;
            img_col = 5;
            img_col_sm = 3;

            shadow = "shadow";
            width = "97%";

            break;

        case "ach_antd_table":
            text_col_md = 11;
            text_col_lg = 10;
            img_col_md = 1;
            img_col_lg = 2;

            text_col_xl = 11;
            img_col_xl = 1;

            text_col = 7;
            text_col_sm = 10;
            img_col = 5;
            img_col_sm = 2;

            shadow = "";
            width = "100%";

            break;

        default:
            break;
    }
    let tmp_tooltip = null;
    if (achievement?.tooltipCached) {
        tmp_tooltip = achievement?.tooltipCached;
    } else if (achievement?.Achievement?.tooltipCached) {
        tmp_tooltip = achievement?.Achievement?.tooltipCached;
    }

    // const generated_dirname = process.env.NEXT_PUBLIC_BACKEND_DIR + "/ach_badge/generated/" + lowerCaseUnderscoredString(achievement?.Achievement?.type ?? "");
    const generated_dirname = "/ach_badge/generated/" + lowerCaseUnderscoredString(achievement?.Achievement?.type ?? "");
    // maybe have an img here in case there is an error
    let tmp_src = generated_dirname;

    // dev_console_log("achievement?.Achievement?.iconType");
    // dev_console_log(achievement?.Achievement?.iconType);
    // dev_console_log("achievement?.Achievement");
    // dev_console_log(achievement?.Achievement);
    // dev_console_log("achievement");
    // dev_console_log(achievement);



    const dateObj = new Date(achievement?.createdAt);
    // // dev_console_log("dateObj");
    // // dev_console_log(dateObj);
    const written_date = `${dateObj.getHours()}:${dateObj.getMinutes()} - ${dateObj.getDate()}/${dateObj.getMonth() + 1}/${dateObj.getFullYear()}`;

    return (
        <div key={achievement?._id}
            className={` flex items-center p-4 bg-white ${shadow} rounded ${CustomBootstrap['row']} `}
            style={{
                width,
            }}
        >
            <div
                style={{
                    // width: "30%",
                    // marginRight: "15px",
                }}
                className={` ${CustomBootstrap['col-5']}  ${CustomBootstrap[`col-sm-${img_col_sm}`]}  ${CustomBootstrap[`col-md-${img_col_md}`]}   ${CustomBootstrap[`col-lg-${img_col_lg}`]}  ${CustomBootstrap[`col-xl-${img_col_xl}`]} `}
            >
                <Tooltip
                    title={tmp_tooltip}
                    placement="top"
                    arrow
                    TransitionComponent={Zoom}
                    describeChild
                >
                    {
                        (() => {

                            switch (achievement?.Achievement?.iconType) {
                                case fontawesome_type:
                                    tmp_src = achievement?.Achievement?.icon?.fontawesome ?? "";
                                    const tmp_color = achievement?.Achievement?.icon?.color;
                                    return (
                                        <FontAwesomeIcon
                                            icon={`fa-solid ${tmp_src}`}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                color: tmp_color ?? "#3b82f6",
                                            }}
                                        />
                                    );
                                case svg_placeholder:
                                    if (achievement?.iconCached) {
                                        tmp_src += achievement?.iconCached;
                                    } else if (achievement?.Achievement?.iconCached) {
                                        tmp_src += achievement?.Achievement?.iconCached;
                                    }
                                    tmp_src = `${tmp_src}.svg`;
                                    return (
                                        <img
                                            src={tmp_src}
                                            alt={tmp_tooltip ?? ""}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    );
                                case from_another_table:
                                    if (achievement?.iconCached) {
                                        tmp_src = achievement?.iconCached;
                                    } else if (achievement?.Achievement?.iconCached) {
                                        tmp_src = achievement?.Achievement?.iconCached;
                                    }
                                    return (
                                        <img
                                            src={tmp_src}
                                            alt={tmp_tooltip ?? ""}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    );

                                default:
                                    return null;
                            }


                        })()
                    }
                </Tooltip>
            </div>
            <div
                className={` ${CustomBootstrap['col-7']} ${CustomBootstrap[`col-sm-${text_col_sm}`]} ${CustomBootstrap[`col-md-${text_col_md}`]}   ${CustomBootstrap[`col-lg-${text_col_lg}`]}  ${CustomBootstrap[`col-xl-${text_col_xl}`]} `}
            >
                <h3 className="text-lg font-semibold">{achievement?.titleCached ?? (achievement?.Achievement?.title ?? (achievement?.Achievement?.type ?? ""))}</h3>
                <p>{achievement?.msgCached ?? (achievement?.Achievement?.msg ?? "")}</p>
                <div className="text-gray-500 metallic">{written_date}</div>
            </div>
        </div>
    );
}

export default AchievementSingleComp