"use client"

import { BACKEND_ROOT } from "@constants"
import { AxiosRequest , dev_console_log } from "@utils"
import { Fragment, useState } from "react"
import Swal from "sweetalert2"
import PostForm from "./PostForm"
import { CustomModal, withStore } from "@components"
import PostOptions from "./PostOptions"


const PostCardOptions = ({ p }) => {
    const [modal, setModal] = useState({ open: false })

    // helpers
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    // post layer
    const handleEditPost = async (content) => {
        await AxiosRequest.put(`${BACKEND_ROOT}/forum/updatePost/${p._id}`, { content })
            .then(res => {
                const postContent = document.getElementById(`post-${p._id}-content`)
                postContent.innerHTML = res.data.content

                closeModal()
            }).catch(err => {
                dev_console_log(err);
            })
    }


    const handleDeletePost = () => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm delete!",
            showConfirmButton: false,
            showDenyButton: true,
            denyButtonText: "Delete",
            showCloseButton: true,
            showCancelButton: true,
            showLoaderOnDeny: true
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.delete(`${BACKEND_ROOT}/forum/deletePost/${p._id}`)
                    .then(res => {
                        if (res.status == 200) {
                            const postCard = document.getElementById(`post-${p._id}`)
                            postCard.remove()
                        }
                    }).catch(err => {
                        dev_console_log(err);
                    })
            }
        }).catch(err => {
            dev_console_log(err)
        })
    }


    const handleReportPost = () => {
        Swal.fire({
            icon: "warning",
            title: "Please, Confirm report!",
            showConfirmButton: false,
            showDenyButton: true,
            showCloseButton: true,
            showCancelButton: true,
            denyButtonText: "Report",
        }).then(async result => {
            if (result.isDenied) {
                await AxiosRequest.post(`${BACKEND_ROOT}/forum/report/Post/${p._id}`)
                    .then(res => {
                        if (res.status == 201) {
                            const postCard = document.getElementById(`post-${p._id}`)
                            postCard.remove()
                        }
                    }).catch(err => {
                        dev_console_log(err);
                    })
            }
        }).catch(err => {
            dev_console_log(err)
        })
    }


    return (
        <Fragment>
            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-4xl scale-90`}>
                <PostForm initial={p} handleFormAction={handleEditPost} />
            </CustomModal>

            <PostOptions
                p={p}
                handleEditPost={() => openModal()}
                handleDeletePost={handleDeletePost}
                handleReportPost={handleReportPost}
            />
        </Fragment>
    )
}

export default withStore(PostCardOptions)