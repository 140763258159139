"use client"

import { useTranslate } from "@hooks"
import { getHumanReadableDuration, dev_console_log } from "@utils"
import Link from "next/link"
import { useParams } from "next/navigation"
import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters"
import { Fragment } from "react"
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import colors from 'tailwindcss/colors'
import { withStore } from "./StoreProvider"


const CourseWork = ({ assessments, exams }) => {
    const translate = useTranslate()
    const { lang } = useParams()

    const lineData = assessments.items.sort((a1, a2) => new Date(a1.createdAt) - new Date(a2.createdAt)).map(a => {

        return { score: a.percentageScore, date: new Date(a.createdAt).toDateString() }
    })
    const rtl = document.dir == 'rtl'

    const passedCount = assessments.items.filter(a => a.percentageScore >= 50).length
    const totalCount = assessments.items.length
    const successRate = passedCount / totalCount * 100

    return (
        <TabGroup as={Fragment}>
            <TabList className={`flex gap-x-2`}>
                <Tab className={`text-gray-500 capitalize mx-auto px-4 py-2 data-[selected]:font-semibold data-[selected]:border-sky-500 border-2 rounded border-gray-300 focus:outline-none data-[selected]:text-sky-500 transition-colors duration-300`}>{translate(`My Performance`)}</Tab>
                <Tab className={`text-gray-500 capitalize mx-auto px-4 py-2 data-[selected]:font-semibold data-[selected]:border-sky-500 border-2 rounded border-gray-300 focus:outline-none data-[selected]:text-sky-500 transition-colors duration-300`}>{translate(`My Assessments`)}</Tab>
            </TabList>

            <TabPanels as={Fragment}>
                <TabPanel className="flex-grow flex flex-col gap-y-2">
                    {/* passed / total */}
                    <h1 className="flex items-center gap-x-1 capitalize text-xl">
                        <span className="font-semibold text-gray-500">{translate("passed")} / {translate("total")}:</span>
                        <span className={successRate >= 50 ? "text-green-500" : "text-red-500"}>{passedCount} / {totalCount}</span>
                    </h1>

                    {/* chart */}
                    <div className="overflow-x-auto small-scroll">
                        <LineChart width={800} height={500} margin={{ bottom: 100, right: 10 }} data={lineData}>
                            <Line name="% Assessment Score" type="monotone" dataKey="score" stroke={colors.blue[400]} />
                            <CartesianGrid stroke={colors.gray[300]} />
                            <XAxis reversed={rtl} interval={0} padding={{ left: 50, right: 50 }} dataKey="date" tick={{ angle: -80, textAnchor: rtl ? 'start' : 'end' }} />
                            <YAxis orientation={rtl ? 'right' : 'left'} domain={[0, 100]} tickCount={6} tick={{ textAnchor: 'end' }} />
                            <Tooltip />
                            <Legend verticalAlign="top" />
                        </LineChart>
                    </div>

                    {/* summary */}
                    {
                        assessments.summary &&
                        <div className="flex flex-col">
                            <h1 className="text-xl font-semibold text-gray-500">{translate("Summary")}:</h1>
                            <p>{translate(assessments.summary)}</p>
                        </div>
                    }
                </TabPanel>

                <TabPanel className="flex-grow flex flex-col gap-y-2">
                    {
                        exams.length == 0 ?
                        <h1 className="text-center font-semibold text-gray-500">{translate(`You haven't took any exam, yet`)}!</h1>
                        :
                        <Fragment>
                            {
                                exams.map((a, idx) => {
                                    const score = (+a.total_score / +a.outof * 100).toFixed(2)
                                    return (
                                        <Link href={`/${lang}/dashboard/assessment/${a.hash}`} key={idx} className="bg-gray-100 border-2 border-gray-300 shadow p-4 flex flex-col gap-y-2 rounded-lg hover:border-blue-500 transition-all duration-500 hover:scale-[1.02]">
                                            <div className="flex gap-x-2 justify-between items-center flex-wrap">
                                                <h1 className="text-blue-500 text-2xl font-bold">{a.name}</h1>
                                                <div className="text-gray-500 italic flex flex-wrap gap-x-1 font-semibold">
                                                    <span>{translate(`Submitted At:`)}</span>
                                                    <span>{new Date(a.submitdate).toDateString()}</span>
                                                </div>
                                            </div>

                                            <div className="flex gap-x-2 justify-between items-center">
                                                <div className="flex flex-col font-semibold text-gray-500">
                                                    {/* status */}
                                                    <span className="text-lg">{a.status}</span>

                                                    {/* time */}
                                                    <span className="text-sm italic">{getHumanReadableDuration(a.studentstartdate, a.submitdate)}</span>
                                                </div>

                                                {/* score */}
                                                <span className={`text-2xl font-semibold ${+score >= 50 ? 'text-green-600' : 'text-red-600'}`}>
                                                    {score} %
                                                </span>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </Fragment>
                    }
                </TabPanel>
            </TabPanels>
        </TabGroup>
    )
}

export default withStore(CourseWork)