import { BACKEND_ROOT } from "@constants"
import { AxiosRequest } from "@utils"
import PostCard from "./PostCard"
import NewPostButton from "./NewPostButton"
import LoadMorePosts from "./LoadMorePosts"
import { Fragment } from "react"
import translateServer from '@utils_backend_only/serverside.translation'


const CourseForum = async ({ course, user, lang }) => {
    const posts = await AxiosRequest.get(`${BACKEND_ROOT}/forum/listPosts/${course._id}`, {
        params: { limit: 5, skip: 0, }
    }).then(res => res.data)

    return (
        <Fragment>
            <NewPostButton user={user} courseId={course._id} lang={lang}>
                {translateServer(`New Post`, lang)}
            </NewPostButton>

            {
                posts.length == 0 ?
                    <h1 className="text-center font-semibold text-gray-500 text-lg my-2">{translateServer(`This course have no posts, yet!`, lang)}</h1>
                    : posts.map((p, pIdx) => {
                        return (
                            <PostCard
                                key={pIdx}
                                p={p}
                                user={user}
                                lang={lang}
                            />
                        )
                    })
            }

            {posts.length >= 5 && <LoadMorePosts courseId={course._id} skip={posts.length} user={user} lang={lang} />}
        </Fragment>
    )

}

export default CourseForum