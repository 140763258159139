import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { dictReducer, langReducer, userReducer, socketReducer, announcementsReducer } from './reducers';
import localforage from 'localforage';
// import storage from "redux-persist/lib/storage"; // for browser
// import createWebStorage from "redux-persist/lib/storage/createWebStorage";

// const createNoopStorage = () => {
//     console.log("Noop storage");
//     return {
//         getItem(_key) {
//             console.log("get",_key);
//           return Promise.resolve(null);
//         },
//         setItem(_key, value) {
//             console.log("set",_key, value);
//           return Promise.resolve(value);
//         },
//         removeItem(_key) {
//             console.log("remove",_key);
//           return Promise.resolve();
//         },

//     };
// };
// function storage_selector(){
//     const isclient = typeof window !== "undefined";
//     if(isclient){
//         console.log("isClient", window.location.href);
//         return createWebStorage('local');
//     }else{
//         console.log("is Server")
//         return createNoopStorage();
//     }
// }

// const storageFallback =storage_selector();

// Configure persistence
const langPersistConfig = {
    key: 'lang',
    storage: localforage,
};


const dictPersistConfig = {
    key: 'dict',
    storage: localforage,
};


// Persisted reducers
const persistedLangReducer = persistReducer(langPersistConfig, langReducer);
const persistedDictReducer = persistReducer(dictPersistConfig, dictReducer);


// Root reducer
const rootReducer = combineReducers({
    user: userReducer,
    lang: persistedLangReducer,
    dict: persistedDictReducer,
    socket: socketReducer,
    announcements: announcementsReducer,
});


// Configure the store
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export const persistor = persistStore(store)
