"use client"

import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

interface RichBoxProps {
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  fillTheBlack?: any;
  defaultValue?: any;
  height?: any;
  onChangeTxt?: any;
  maxCharCount?: any;
  charCounter?: any;
  placeholder?: any;
  ref?: any;
  name?: string;
  id?: string;
  className?: string;
}


function makeId(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
      // call on next available tick
      setTimeout(fn, 1);
  } else {
      document.addEventListener("DOMContentLoaded", fn);
  }
}    

function isNullOrUndefined(obj: any) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return false;
}


declare class FroalaEditor {
  constructor(param: any, conf: any);
}


const RichBox = (props: RichBoxProps) => {
  const [id, setId] = useState<string>(props.id ?? "000");// = makeId(5);
  const [froala, setFroala] = useState<any>(null);
  const [CrTextEditor, setCrTextEditor] = useState(false)

  useEffect(() => {
    if (id == "000") {
      setId(makeId(10));
    }
    let w: any = window;
    if (w.CreateTextEditor === undefined || typeof w.CreateTextEditor !== 'function') {
      const GetFroala = async () => {
        // Swal.showLoading();
        w.FROALA_BASE_LINK = "https://cloud.ivyis.org/front-end/";
        let resources = await axios.get("https://cloud.ivyis.org/front-end/froala/json.php");
        let head = document.getElementsByTagName("head")[0];
        resources.data.css.forEach((css: string) => {
          let style = document.createElement("link");
          style.rel = "stylesheet";
          style.href = css;
          head.appendChild(style);
        });
        loadJsSync(resources);

      }
      if (w.isFroalaLoaded === true) {
        initRichBox();
      } else {
        GetFroala();
        w.onFroalaLoad = () => {
          initRichBox();
        }
      }
    } else {
      if (CrTextEditor === false) {
        setCrTextEditor(w.true);
      }
    }
  })


  useEffect(() => {
    if (froala === null) {
      initRichBox();
    }
  }, [id, CrTextEditor])

  const loadJsSync = (resources: any) => {
    let w: any = window;
    let head = document.getElementsByTagName("head")[0];
    w.scripts = resources.data.js.length;
    let index = 0;
    const onload = () => {
      if (index >= w.scripts) {
        let w: any = window;
        w.FROALA_BASE_LINK = "https://cloud.ivyis.org/front-end/";
        if (w.FroalaLoadHandlers === undefined) w.FroalaLoadHandlers = [];
        w.onFroalaLoaded = () => {
          if (w.FroalaLoadHandlers !== undefined) w.FroalaLoadHandlers.forEach((func: any) => func());
        }; w.CreateTextEditor = (e: any, maxChar = 9999999) => {
          return new FroalaEditor(e, {
            charCounterMax: maxChar, events: {
              "image.beforeUpload": function (e: any) {
                if (w.editor = this, e.length) {
                  let t = e[0], o = new FormData; if (Swal && typeof Swal.showLoading === "function") {
                  } o.append("photo", t), fetch(w.FROALA_BASE_LINK + "froala/php/postfile.php", { method: "POST", body: o }).then(e => e.text()).then(e => { w.editor.image.insert(e, null, null, w.editor.image.get()); if (Swal && typeof Swal.showLoading === "function") { Swal.close(); } })
                } return !1
              }, "video.beforeUpload": function (e: any) {
                if (w.editor = this, e.length) {
                  let t = e[0], o = new FormData; if (Swal && typeof Swal.showLoading === "function") {
                  }; o.append("photo", t), fetch(w.FROALA_BASE_LINK + "froala/php/postfile.php", { method: "POST", body: o }).then(e => e.text()).then(e => { w.editor.video.insert('<video src="' + e + '" style="width: 600px;" controls="" class="fr-draggable">Your browser does not support HTML5 video.</video>'); if (Swal && typeof Swal.showLoading === "function") { Swal.close(); } })
                } return !1
              }
            }
          })
        };
        w.onFroalaLoaded();
        setCrTextEditor(true);
        w.isFroalaLoaded = true;
        initRichBox();
        // Swal.close();
        return;
      };
      let script = document.createElement("script");
      script.src = resources.data.js[index];
      index += 1;
      script.type = "text/javascript";
      head.appendChild(script);
      script.onload = onload;
    }
    let main = document.createElement("script");
    main.src = resources.data.js[index];
    main.type = "text/javascript";
    head.appendChild(main);
    index += 1;
    main.onload = onload;
  }

  const loadJsAsync = (resources: any) => {
    let w: any = window;
    let head = document.getElementsByTagName("head")[0];
    w.scripts = resources.data.js.length;
    let index = 0;
    const onload = () => {
      index += 1;
      if ((index + 2) >= w.scripts) {
        let w: any = window;
        w.FROALA_BASE_LINK = "https://cloud.ivyis.org/front-end/";
        if (w.FroalaLoadHandlers === undefined) w.FroalaLoadHandlers = [];
        w.onFroalaLoaded = () => {
          if (w.FroalaLoadHandlers !== undefined) w.FroalaLoadHandlers.forEach((func: any) => func());
        }; w.CreateTextEditor = (e: any, maxChar = 9999999) => {
          return new FroalaEditor(e, {
            charCounterMax: maxChar, events: {
              "image.beforeUpload": function (e: any) {
                if (w.editor = this, e.length) {
                  let t = e[0], o = new FormData; if (Swal && typeof Swal.showLoading === "function") {
                  } o.append("photo", t), fetch(w.FROALA_BASE_LINK + "froala/php/postfile.php", { method: "POST", body: o }).then(e => e.text()).then(e => { w.editor.image.insert(e, null, null, w.editor.image.get()); if (Swal && typeof Swal.showLoading === "function") { Swal.close(); } })
                } return !1
              }, "video.beforeUpload": function (e: any) {
                if (w.editor = this, e.length) {
                  let t = e[0], o = new FormData; if (Swal && typeof Swal.showLoading === "function") {
                  }; o.append("photo", t), fetch(w.FROALA_BASE_LINK + "froala/php/postfile.php", { method: "POST", body: o }).then(e => e.text()).then(e => { w.editor.video.insert('<video src="' + e + '" style="width: 600px;" controls="" class="fr-draggable">Your browser does not support HTML5 video.</video>'); if (Swal && typeof Swal.showLoading === "function") { Swal.close(); } })
                } return !1
              }
            }
          })
        };
        w.onFroalaLoaded();
        setCrTextEditor(true);
        w.isFroalaLoaded = true;
        initRichBox();
        // Swal.close();
        return;
      };
    }
    resources.data.js.forEach((src: string) => {
      let main: any = document.querySelector("script[src='" + src + "']");
      if (main === null) {
        main = document.createElement("script");
      } else {
        index += 1;
      }
      main.src = src;
      main.type = "text/javascript";
      head.appendChild(main);
      main.onload = onload;
    });
  }

  const initRichBox = () => {
    if (froala === null) {
      let w: any = window;
      if (id === "000") return;
      if (typeof (w.CreateTextEditor) === 'function') {
        const F = w.CreateTextEditor("#" + id, props.maxCharCount ?? 9999999);

        docReady(() => {
          F.html.set(id)

          F.html.set(props.defaultValue ?? "")
          F.events.on("contentChanged", () => { onChange(F.html.get()) })
        })
        setFroala(F);
        w.f = F;
      }
    }
  }

  const onChange = (contents: any) => {

    if (!isNullOrUndefined(props.onChange)) props.onChange(contents);
    onChangeTxt(contents);
  };

  const onChangeTxt = (contents: any) => {

    if ((!isNullOrUndefined(props.onChangeTxt)) && (!isNullOrUndefined(froala)))
      props.onChangeTxt(froala?.getText());
  };

  return (
    <textarea
      id={id}
      name={props.name}
      className={props.className}
      disabled
      defaultValue={"Loading.... Please wait until text input load"}
    />
  )

}

export default RichBox;