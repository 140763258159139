import {AiOutlineLoading3Quarters} from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";

const Loading = () => {
    return (
        <div className="flex items-center w-1/6 h-fit mx-auto my-4 animate-pulse">
            <AiOutlineLoading3Quarters className="animate-spin text-dark-blue w-full h-full" />
        </div>
    )
}

export default Loading