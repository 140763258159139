"use client"

import { FlyInOutTransition, ProgressHeatmap } from '@components'
import ProgressBar from '@ramonak/react-progress-bar'
import { useEffect, useState } from 'react'
import {AiOutlineSafetyCertificate} from "@react-icons/all-files/ai/AiOutlineSafetyCertificate"
import {MdExpandMore} from "@react-icons/all-files/md/MdExpandMore";
import Link from 'next/link'
import { useTranslate } from '@hooks'


const ProgressCourseCard = ({ progress }) => {
    const translate = useTranslate()

    const [expand, setExpand] = useState(false)
    const [completion, setCompletion] = useState(0)

    useEffect(() => {
        const totalLOs = progress.learningPerformance.length
        const completedLOs = progress.learningPerformance.filter(lp => lp.level >= 2).length
        const completionRatio = ((completedLOs / totalLOs) * 100).toFixed(2)
        setCompletion(+completionRatio > 5 ? completionRatio : 5)
    }, [progress])

    return (
        <div className={`w-full flex flex-col p-4 rounded-lg shadow bg-gray-100 border border-gray-300 transition-all`}>
            <div className="flex justify-between gap-x-2">
                {/* details */}
                <div className="flex flex-col justify-between gap-y-4 w-full">
                    {/* title */}
                    <button className="w-fit flex items-center gap-x-2 hover:underline font-bold text-2xl text-blue-400" onClick={() => setExpand(!expand)}>
                        <h1>{progress.course.title}</h1>
                        <MdExpandMore className={`transition-transform duration-500 ${expand ? 'rotate-180' : 'rotate-0'}`} />
                    </button>

                    {/* learning outcomes */}
                    <FlyInOutTransition show={expand} as="h2" className={`${expand ? "flex gap-x-2 font-semibold text-gray-400" : "hidden"}`}>
                        <span>{translate(`Number of Learning Outcomes:` )}</span>
                        <span>{progress.course.learningOutcomes.length}</span>
                    </FlyInOutTransition>

                    {/* completion */}
                    <FlyInOutTransition show={!expand} as="div" className={`${expand ? 'hidden' : 'flex flex-col'}`}>
                        <h1 className='text-blue-400 font-bold'>{translate(`Completion Percentage:` )}</h1>
                        <ProgressBar
                            baseBgColor='#DBEAFE'
                            bgColor='#60A5FA'
                            completed={completion}
                        />
                    </FlyInOutTransition>

                    {/* buttons */}
                    <div className='flex gap-x-2 text-sm'>
                        <Link href={`dashboard/course-progress/${progress.course._id}`} className='submit-btn'>{translate(`Continue Learning` )}</Link>
                        <button className='submit-btn-outline'>{translate(`Benchmark Assessment` )}</button>
                    </div>
                </div>

                {/* logo */}
                <AiOutlineSafetyCertificate className="h-[150px] w-fit text-blue-400 max-md:hidden" />
            </div>

            {/* dynamic gap */}
            <div className={`w-full transition-all duration-500 ${expand ? 'h-4' : 'h-0'}`} />

            {/* heatmap */}
            <ProgressHeatmap p={progress} expand={expand} />

            {/* dynamic gap */}
            <div className={`w-full transition-all duration-500 ${expand ? 'h-4' : 'h-0'}`} />

            {/* completion */}
            <div className={`flex flex-col transition-all duration-500 ${expand ? 'h-[44px] overflow-auto' : 'h-0 overflow-hidden'}`}>
                <h1 className='text-blue-400 font-bold'>{translate(`Completion Percentage:` )}</h1>
                <ProgressBar
                    baseBgColor='#DBEAFE'
                    bgColor='#60A5FA'
                    completed={completion}
                />
            </div>
        </div>
    )
}

export default ProgressCourseCard