"use client"

import { CustomModal, LearningTimesForm, withStore } from "@components"
import { Fragment, useState } from "react"
import { useParams, useRouter } from "next/navigation"
import { AxiosRequest , dev_console_log } from "@utils"
import { BACKEND_ROOT } from "@constants"


const EnrollCourseButton = ({ user, children, courseId }) => {
    const { lang } = useParams()
    const router = useRouter()

    const [modal, setModal] = useState({ open: false })
    const openModal = (params) => setModal({ open: true, ...params })
    const closeModal = () => setModal({ open: false })

    const handleEnroll = async (body) => {
        await AxiosRequest.post(`${BACKEND_ROOT}/course/createCourseProgress/${courseId}`, body)
            .then(res => {
                closeModal()
                router.push(`/${lang}/dashboard/course-progress/${res.data._id}`)
            }).catch(err => {
                dev_console_log(err);
            })
    }

    const LearningTimesFormWithStore = withStore(LearningTimesForm)
    return (
        <Fragment>
            <button className="submit-btn w-fit text-sm" onClick={() => {
                if (!user) return router.push(`/${lang}/login`);

                openModal()
            }}>
                {children}
            </button>

            <CustomModal open={modal.open} onClose={closeModal} className={`max-w-[1080px] min-w-[720px]`}>
                <LearningTimesFormWithStore handleFormAction={handleEnroll} />
            </CustomModal>
        </Fragment>
    )
}

export default EnrollCourseButton